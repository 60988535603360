import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyBoLDp9vKEoSKiOnNsDqrxyXkaJSufPaYA",
  authDomain: "hicard-f4447.firebaseapp.com",
  projectId: "hicard-f4447",
  storageBucket: "hicard-f4447.appspot.com",
  messagingSenderId: "1015625289105",
  appId: "1:1015625289105:web:5479ed4d70ad1d0cae5e30",
  measurementId: "G-G0R7S8EGKS",
}

export const getFirebase = () => {
  if (typeof window !== "undefined") {
    return initializeApp(firebaseConfig)
  }
  return null
}

export const getDB = () => {
  if (typeof window !== "undefined") {
    let app = initializeApp(firebaseConfig)
    return getFirestore(app)
  }
  return null
}

export const getST = () => {
  if (typeof window !== "undefined") {
    let app = initializeApp(firebaseConfig)
    return getStorage(app)
  }
  return null
}
